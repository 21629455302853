import axios from "axios";
import { format } from "date-fns";
import React, { useState, useMemo, useEffect } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import Spinner from "../../../../commons/components/spinner";
import Table from "../../../../commons/components/table";
import commonConfigs from "../../../../config/config";
import { roundToTwo } from "../../../../utils/custom/helper";

const pages = [
  { name: "Group Purchase Order", href: "#", current: false },
  {
    name: "Services",
    href: "/grouporder/services",
    current: true,
  },
];

const getGPOByLocationSupplierGoods = commonConfigs.apiUrls.GetGPOByLocationSupplierServices();

const ServicesLayout = () => {

  const [GPOByLocationSupplierGoods, setGPOByLocationSupplierGoods] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedSubRow, setSelectedSubRow] = useState(null);
  
  const [load, setLoad] = useState(true);
  let user = JSON.parse(localStorage.getItem("user"))

  async function fetchGPOByLocationSupplierGoods() {
    let arr = []
    await axios
      .get(getGPOByLocationSupplierGoods, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        let response = res.data.result.data;
        if(user.location_id && (user.access_multiple_location === 0 || !user.access_multiple_location)) {
          arr.push(response.find(({location_id}) => user.location_id === location_id))
          setGPOByLocationSupplierGoods(arr) 
        } else {
          setGPOByLocationSupplierGoods(res.data.result.data);
        }
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      fetchGPOByLocationSupplierGoods().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Location Name",
      accessor: "location_name",
    },
    {
      Header: "Total Amount",
      accessor: "total_amt",
      Cell: ({ row }) => {
        return (
          <p className="text-sm text-gray-900">
            ${roundToTwo(row.values.total_amt)}
          </p>
        );
      },
    },
    {
      Header: "Merchant Details",
      id: "expander",
      Cell: (props) => {
        const isRowSelected = selectedRow === props.row.original;
        return (
          <button
            className="underline"
            onClick={() => {
              props.toggleAllRowsExpanded(false);
              props.row.toggleRowExpanded();
              setSelectedRow(props.row.original);
              toggleRowSelection(props.row.original);
              console.log(props.row.original);
            }}
          >
            {isRowSelected ? "Collapse" : "View More"}
          </button>
        );
      },
    },
  ]);

  const merchantExpandedCols = React.useMemo(() => [
    {
      Header: "Merchant Name",
      accessor: "merchant_name",
    },
    {
      Header: "Product Details",
      id: "expander",
      Cell: (props) => {
        const isSubRowSelected = selectedSubRow === props.row.original;
        return (
          <button
            className="underline"
            onClick={() => {
              props.toggleAllRowsExpanded(false);
              props.row.toggleRowExpanded();
              setSelectedSubRow(props.row.original);
              toggleSubRowSelection(props.row.original);
              console.log(props.row.original);
            }}
          >
            {isSubRowSelected ? "Collapse" : "View More"}
          </button>
        );
      },
    },
  ]);

  const orderExpandedCols = React.useMemo(() => [
    {
      Header: "Product Name",
      accessor: "product_name",
    },
    {
      Header: "SKU",
      accessor: "product_sku",
    },

    {
      Header: "Quantity",
      accessor: "order_qty",
    },

    {
      Header: "Amount",
      accessor: "order_amt",
      Cell: ({ row }) => {
        return (
          <p className="text-sm text-gray-900">
            ${roundToTwo(row.values.order_amt)}
          </p>
        );
      },
    },

    {
      Header: "Total Price",
      accessor: "total_price",
      Cell: ({ row }) => {
        return (
          <p className="text-sm text-gray-900">
            ${roundToTwo(row.values.total_price)}
          </p>
        );
      },
    },
    {
      Header: "Status",
      accessor: "order_details_status",
      Cell: ({ row }) => {
        let status = row.values.order_details_status;
        if (status == 'Order Pending') {
          return <div className="text-sm text-yellow-400">Pending</div>;
        } 
        else if (status == 'Order Declined'){
          return <div className="text-sm text-red-700">Declined</div>;
        }
        else {
          return <div className="text-sm text-green-700">Confirmed</div>;
        }
      },
    },
    {
      Header: "Order Date",
      accessor: "order_datetime",
      Cell: ({ row }) => {
        row.values.order_datetime = format(
          new Date(row.values.order_datetime),
          "dd MMM yyyy HH:mm"
        );
        var date = row.values.order_datetime;
        if (date) {
          return <div className="text-sm text-gray-900">{date}</div>;
        }
      },
    },
    {
      Header: "Order Delivery Date",
      accessor: "order_delivery_datetime",
      Cell: ({ row }) => {
        let date = row.values.order_delivery_datetime;
    
        if (date) {
          date = format(new Date(date), "dd MMM yyyy HH:mm");
        } else {
          date = "-"; // Set the value to "TBC" if date is null
        }
    
        return <div className="text-sm text-gray-900">{date}</div>;
      },
    },    
  ]);

  const toggleRowSelection = (row) => {
    if (selectedRow === row) {
      setSelectedRow(null);
    } else {
      setSelectedRow(row);
    }
  };

  const toggleSubRowSelection = (row) => {
    if (selectedSubRow === row) {
      setSelectedSubRow(null);
    } else {
      setSelectedSubRow(row);
    }
  };

  return (
    <div className="w-full desktop:p-6 mobile:p-2 ">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2">
          <Breadcrumbs pages={pages} />
          {GPOByLocationSupplierGoods ? (
            <div className="mt-5">
              <Table
                columns={columns}
                data={GPOByLocationSupplierGoods}
                filters={columns.map((column) => column.accessor)}
                expandedTable={
                  selectedRow ? (
                    <Table
                      columns={merchantExpandedCols}
                      data={selectedRow.merchants}
                      filters={merchantExpandedCols.map(
                        (column) => column.accessor
                      )}
                      hasSearch={false}
                      hasPagination={false}
                      expandedTable={
                        selectedSubRow ? (
                          <Table
                            columns={orderExpandedCols}
                            data={selectedSubRow.orders}
                            filters={orderExpandedCols.map(
                              (column) => column.accessor
                            )}
                            hasSearch={false}
                            hasPagination={true}
                          />
                        ) : (
                          null
                        )
                      }
                    />
                  ) : (
                    null
                  )
                }
              />
            </div>
          ) : (
            <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
              <div className="flex place-content-center w-full">
                <Spinner className="w-20 h-20" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServicesLayout;
