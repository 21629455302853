import React, { useState, useMemo, useEffect } from "react";
import Breadcrumbs from "../../../../commons/components/breadcrumbs";
import commonConfigs, { org_id } from "../../../../config/config";
import axios from "axios";
import Table from "../../../../commons/components/table";
import Spinner from "../../../../commons/components/spinner";
import { format } from "date-fns";
import GeneralModal from "../../../../commons/components/generalModal";
import DeclineProductModal from "../components/declineProductModal";
import { roundToTwo } from "../../../../utils/custom/helper";
// import { org_id } from "../../../../config/config";

const pages = [
  { name: "Group Purchase Order", href: "#", current: false },
  { name: "Orders", href: "/grouporder/goods", current: true },
];

const getGPOBySupplier = commonConfigs.apiUrls.GetGPOBySupplier();

const OrdersLayout = () => {
  const [GPOBySupplier, setGPOBySupplier] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedSubRow, setSelectedSubRow] = useState(null);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isConfirmItemModalOpen, setIsConfirmItemModalOpen] = useState(false);
  const [confirmAPORow, setConfirmAPORow] = useState(null);
  
  
  const [load, setLoad] = useState(true);

  async function fetchGPOBySupplier() {
    await axios
      .get(getGPOBySupplier, { headers: commonConfigs.apiHeader })
      .then((res) => {
        console.log(res.data.result.data);
        setGPOBySupplier(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (load) {
      fetchGPOBySupplier().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Supplier Name",
      accessor: "supplier_name",
    },
    {
      Header: "PO Order Id",
      accessor: "merchant_po_id",
      Cell: ({ row }) => {
        if (row.values.merchant_po_id) {
          return (
            <p className="text-sm font-semibold text-gray-900">
              {row.values.merchant_po_id}
            </p>
          );
        } else {
          return <p className="text-sm font-semibold text-gray-900">-</p>;
        }
      },
    },
    {
      Header: "Confirmation Date",
      accessor: "po_datetime",
      Cell: ({ row }) => {
        if (row.values.po_datetime) {
          row.values.po_datetime = format(
            new Date(row.values.po_datetime),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.po_datetime;

          if (date) {
            return <p className="text-sm text-gray-900">{date}</p>;
          }
        }
        return <p className="text-sm text-gray-900">-</p>;
      },
    },
    {
      Header: "PO Status",
      accessor: "status",
      Cell: ({ row }) => {
        if (row.values.status == "PO Number") {
          row.values.status = "Confirmed";
        }
        return (
          <span
            className={`${
              row.values.status == "Order Confirmed"
                ? "bg-green-200"
                : row.values.status == "Order Pending"
                ? "bg-yellow-200"
                : "bg-red-200"
            } inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800`}
          >
            {row.values.status}
          </span>
        );
      },
    },

    {
      Header: "PO Total Amount",
      accessor: "total_amt",
      Cell: ({ row }) => {
        return (
          <p className="text-sm text-gray-900">
            ${roundToTwo(row.values.total_amt)}
          </p>
        );
      },
    },
    {
      Header: "Details",
      id: "expander",
      Cell: (props) => {
        return (
          <button
            className="underline"
            onClick={() => {
              console.log(props);
              props.toggleAllRowsExpanded(false);
              props.row.toggleRowExpanded();
              setSelectedRow(props.row.original);
              console.log(props.row.original);
            }}
          >
            View More
          </button>
        );
      },
    },
    {
      Header: "Action",
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900 flex gap-x-2">
            {console.log("status", row.values.status)}
            {row.values.status === "Order Confirmed" ? (
              <button
                onClick={() => {
                  console.log("row at download po", row);
                  setSelectedRow(row);
                  setIsDownloadModalOpen(true);
                }}
                type="button"
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-green-200 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Download PO
              </button>
            ) : null}
            {row.values.status === "Order Pending" ? (
              <button
                onClick={() => {
                  setSelectedRow(row);
                  setIsConfirmModalOpen(true);
                }}
                type="button"
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-gray-200 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Accept PO
              </button>
            ) : null}
          </div>
        );
      },
    },    
  ],[]);

  const merchantExpandedCols = React.useMemo(() => [
    {
      Header: "Product Name",
      accessor: "product_name",
    },
    {
      Header: "Product SKU",
      accessor: "product_sku",
    },
    {
      Header: "Merchant Name",
      accessor: "merchant_name",
    },
    {
      Header: "Merchant Address",
      accessor: "merchant_address",
      Cell: ({ value }) => value ? value : '-'
    },
    {
      Header: "Quantity",
      accessor: "order_qty",
    },
    {
      Header: "Total Price",
      accessor: "total_price",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        let status = row.original.status;
        if (status == 'Order Pending') {
          return <div className="text-sm text-yellow-400">Pending</div>;
        } 
        else if (status == 'Order Declined'){
          return <div className="text-sm text-red-700">Declined</div>;
        }
        else {
          return <div className="text-sm text-green-700">Confirmed</div>;
        }
      },
    },
    {
      Header: "Order Delivery Date",
      accessor: "order_delivery_datetime",
      Cell: ({ row }) => {
        let date = row.values.order_delivery_datetime;
    
        if (date) {
          date = format(new Date(date), "dd MMM yyyy HH:mm");
        } else {
          date = "-"; // Set the value to "TBC" if date is null
        }
    
        return <div className="text-sm text-gray-900">{date}</div>;
      },
    },    
    {
      Header: "Action",
      id: "edit",
      Cell: ({ row }) => {
        // {console.log("supplier_id", selectedRow.supplier_id)}
        // {console.log("merchant_details_id", row.original.merchant_details_id)}
        // {console.log("selectedRow", selectedRow)}
        // {console.log("row.original", row.original)}
        
        if (row.original.status === "Order Pending") {
          return (
            <div className="text-sm text-gray-900 flex gap-x-1">
              <button
                onClick={() => {
                  setSelectedSubRow(row.original);
                  setIsDeclineModalOpen(true);
                }}
                type="button"
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-red-200 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Decline Item
              </button>
              
              <button
                onClick={() => {
                  setSelectedSubRow(row.original);
                  setIsConfirmItemModalOpen(true);
                  setConfirmAPORow(selectedRow);
                }}
                type="button"
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-green-200 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Accept Item
              </button>
            </div>
          );
        } else {
          return null; // Hide the button if the status is not "Decline"
        }
      },
    },  
  ]);

  return (
    <div className="w-full p-6">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      {GPOBySupplier ? (
        <div className="mt-5">
          <Table
            columns={columns}
            data={GPOBySupplier}
            filters={columns.map((column) => column.accessor)}
            expandedTable={
              selectedRow && selectedRow.orders ? (
                <Table
                    columns={merchantExpandedCols}
                    data={selectedRow.orders}
                    filters={merchantExpandedCols.map((column) => column.accessor)}
                    hasSearch={false}
                    hasPagination={true}
                    />
              ) : null
            }
          />
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}

      {isDownloadModalOpen && (
        <GeneralModal
          title={"Download PO?"}
          description={"Do you want to confirm download PO?"}
          setModalOpen={setIsDownloadModalOpen}
          successCallback={() => {
            console.log("PO id", selectedRow.values.merchant_po_id);
            axios
              .get(
                commonConfigs.apiUrls.DownloadPOOrderById(
                  selectedRow.values.merchant_po_id
                ),
                {
                  responseType: "arraybuffer",
                  headers: commonConfigs.apiHeader,
                }
              )
              .then((response) => {
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );

                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                  "download",
                  "po_" + selectedRow.values.merchant_po_id + ".xlsx"
                );
                document.body.appendChild(link);
                link.click();
              });
          }}
        />
      )}
      {isConfirmModalOpen && (
        <GeneralModal
          title={"Confirm Accept PO?"}
          description={"Do you want to accept this PO?"}
          setModalOpen={setIsConfirmModalOpen}
          successCallback={() => {
            axios
              .post(
                commonConfigs.apiUrls.ConfirmGPOBySupplierId(
                ),
                {
                  supplier_id: selectedRow.original.supplier_id,
                  org_id: org_id,
                  merchant_order_details_id: selectedRow.original.orders.map(
                    (order) => order.merchant_details_id
                  ),
                },
                {
                  headers: commonConfigs.apiHeader,
                }
              )
              .then((res) => {
                try {
                  if (res.data.status != "success") {
                    alert("danger");
                  } else {
                    alert("success");
                  }
                  setLoad(true);
                  setIsConfirmModalOpen(false);
                } catch (err) {
                  console.log(err);
                }
              })
              .catch((err) => {
                const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
              });
          }}
        />
      )}

      {isConfirmItemModalOpen && (
        <GeneralModal
          title={"Confirm Accept PO?"}
          description={"Do you want to accept this PO?"}
          setModalOpen={setIsConfirmItemModalOpen}
          successCallback={() => {
            axios
              .post(
                commonConfigs.apiUrls.ConfirmGPOBySupplierId(
                ),
                {
                  supplier_id: confirmAPORow.supplier_id,
                  org_id: org_id,
                  merchant_order_details_id: [selectedSubRow.merchant_details_id]
                },
                {
                  headers: commonConfigs.apiHeader,
                }
              )
              .then((res) => {
                try {
                  if (res.data.status != "success") {
                    alert("danger");
                  } else {
                    alert("success");
                  }
                  setLoad(true);
                  setIsConfirmItemModalOpen(false);
                } catch (err) {
                  console.log(err);
                }
              })
              .catch((err) => {
                const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
              });
          }}
        />
      )}

      {isDeclineModalOpen && (
        <DeclineProductModal
          setIsOpen={setIsDeclineModalOpen}
          onDecline={(reason) => {
            console.log("Product id",selectedSubRow.merchant_details_id);
            console.log(reason);
            axios
              .put(
                commonConfigs.apiUrls.DeclineGPOByMerchantDetailId(
                  selectedSubRow.merchant_details_id
                ),
                { decline_reason: reason },
                {
                  headers: commonConfigs.apiHeader,
                }
              )
              .then((res) => {
                try {
                  if (res.data.status != "success") {
                    alert("danger");
                  } else {
                    alert("success");
                  }

                  setLoad(true);
                  setIsDeclineModalOpen(false);
                } catch (err) {
                  console.log(err);
                }
              })
              .catch((err) => {
                const err_msg = JSON.stringify(err.response.data.result.message);
        alert(`Error code : ${err.response.status} , ${err_msg}`);
              });
          }}
        />
      )}
    </div>
  );
};
export default OrdersLayout;
